// Import our CSS
import styles from '../css/app.pcss';

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    // Create our vue instance
    const vm = new Vue({
        delimiters: ['${', '}'],
        el: "#header",
        created() {
            window.addEventListener('resize', this.handleResize)
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },
        data: {
            menuActive: false,
            searchString: '',
        },
        methods: {
            toggleMenu() {
                this.menuActive = !this.menuActive;
            },
            handleResize() {
                if (window.innerWidth < 1024) {
                    this.menuActive = false;
                }
                if (window.innerWidth >= 1024) {
                    this.menuActive = true;
                }
            },
            handleResize() {
                if (window.innerWidth < 1024) {
                    this.menuActive = false;
                }
                if (window.innerWidth >= 1024) {
                    this.menuActive = true;
                }
            },
            search() {
                this.$refs.search.submit();
            }
        },
        mounted() {
        },
    });

    return vm;
};

// // Execute async function
main().then((vm) => {
    console.log('Header')
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}